import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ButtonLoader from './ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import * as institutionActions from '../redux/actions/institution';
import * as commentActions from '../redux/actions/comments';
import * as institutionTypes from '../redux/types/institution';
import * as rateActions from '../redux/actions/rate';
import emailjs from 'emailjs-com';
import ModalWrapper from './ModalWrapper';
import CustomAlert from './CustomAlert';

export const ReviewModal = ({
  content,
  showReviewModal,
  setShowReviewModal,
  userType,
}) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  console.warn(`rating`, rating);
  const { loading, error, success, errorMessage } = useSelector(
    (state) => state.comments,
  );

  const initialValues = {
    review: '',
  };

  const validationSchema = Yup.object({
    review: Yup.string().required('Review is required'),
  });

  const onSubmit = (values, onSubmitProps) => {
    dispatch(
      rateActions.rateContent(userType, {
        idOfRated: content?._id,
        ratingLevel: rating,
      }),
    );
    dispatch(
      commentActions.addComment({
        commentAbout: userType,
        idOfCommentedAbout: content._id,
        commentText: values.review,
      }),
    );
    onSubmitProps.resetForm();
    setRating(0);
  };

  useEffect(() => {
    if (success.message === 'Comment added successfully') {
      dispatch(commentActions.getAllComments());
      setShowReviewModal(false);
    } else if (error && errorMessage === 'Failed to submit comment') {
      dispatch(commentActions.getAllComments());
      setShowReviewModal(false);
    }
  }, [success, error]);

  return (
    <>
      {showReviewModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              touched,
              errors,
              handleReset,
              getFieldProps,
              isValid,
              onSubmitProps,
            }) => (
              <Form>
                <ModalWrapper
                  title={`Review ${userType}`}
                  closeModal={() => setShowReviewModal(false)}
                  bodyContent={
                    <>
                      <span className='font-semibold text-charcoal text-xl sm:text-3xl w-full mt-1 flex justify-start mb-3'>
                        {`Provide some feedback on this ${userType}. It will take
                          just a minute of your time. Thank you.`}
                      </span>
                      <span className='font-semibold text-charcoal text-lg w-full pt-4 flex justify-start'>
                        Your rating.
                      </span>
                      <div className='flex flex-row text-graybg'>
                        <ReactStars
                          count={5}
                          onChange={(new_rating) => {
                            setRating(new_rating);
                          }}
                          size={50}
                          isHalf={true}
                          emptyIcon={<i className='far fa-star'></i>}
                          halfIcon={<i className='fa fa-star-half-alt'></i>}
                          fullIcon={<i className='fa fa-star'></i>}
                          activeColor='#ffd700'
                          value={rating}
                        />
                      </div>

                      <div className='mt-4'>
                        <textarea
                          className='flex-grow w-full p-4 mb-2
                          transition duration-200 bg-lightgraybg border
                          border-linecolor rounded appearance-none
                          focus:border-darkerblue focus:outline-noone
                          focus:shadow-outline'
                          id='review'
                          name='review'
                          placeholder={
                            'Give an honest opinion about the mentor..'
                          }
                          rows='7'
                          {...getFieldProps('review')}
                        />
                        {touched.review && errors.review ? (
                          <span className='text-sm text-red'>
                            {errors.review}
                          </span>
                        ) : null}
                      </div>
                    </>
                  }
                  footer={true}
                  footerContent={
                    <>
                      <div className='justify-start'>
                        <span className='font-normal text-charcoal text-sm mb-4 flex justify-start   '>
                          By clicking submit, I agree that my feedback may be
                          viewed by the Delv community in compliance with the
                          Delv Terms of use and my profile privacy settings.
                        </span>
                      </div>
                      <div className='flex w-full justify-center  lg:justify-end'>
                        <button
                          type='submit'
                          className='cursor-pointer w-full inline-flex items-center justify-center h-14 text-xl px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                        >
                          {loading ? <ButtonLoader /> : <> Submit Review </>}
                        </button>
                      </div>
                    </>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

export const EmailModal = ({
  content,
  showEmailModal,
  setShowEmailModal,
  userType,
}) => {
  const loading = true;
  const initialValues = {
    email: '',
    name: '',
    subject: '',
    message: '',
  };
  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const validationSchema = Yup.object({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message body is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_dvg8oyv',
        'template_ayj7z6j',
        e.target,
        'user_LhZl7V75cd3jIR3BYcVMN',
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          setValues({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
          setStatus('SUCCESS');
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 100);
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Your Message has been sent',
      });
    }
  }, [status]);

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      {showEmailModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              touched,
              errors,
              handleReset,
              getFieldProps,
              isValid,
              onSubmitProps,
            }) => (
              <Form onSubmit={onSubmit}>
                <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-screen shadow-md sm:mt-2 md:mt-16 '>
                  <div className='mb-4 relative w-screen sm:w-9/12 md:w-7/12 my-auto mx-2 sm:mx-auto  lg:w-6/14'>
                    {/*content*/}
                    <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                      {/*header*/}
                      <div className='flex items-start justify-between px-5 pb-2 pt-4 rounded-t border-b border-linecolor'>
                        <h3 className='text-2xl font-sans font-bold text-charcoal'>
                          Contact Us
                        </h3>
                        <button
                          className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold'
                          onClick={() => setShowEmailModal(false)}
                        >
                          <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                            ×
                          </span>
                        </button>
                      </div>

                      {/*body*/}
                      <div className='relative px-6 pb-6 flex-auto'>
                        <div className='grid w-full'>
                          <div className='form-control'>
                            <input
                              type='email'
                              placeholder={'Your email address'}
                              className='flex-grow w-full h-11 px-4 mt-2 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='fromEmail'
                              name='email'
                              value={values.email}
                              onChange={handleChange}
                            />
                            {touched.email && errors.email ? (
                              <span className='text-sm text-red'>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='grid w-full'>
                          <div className='form-control'>
                            <input
                              placeholder={'Name'}
                              type='text'
                              className='flex-grow w-full h-11 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='toEmail'
                              ml-12
                              value={values.name}
                              onChange={handleChange}
                              name='name'
                            />
                            {touched.name && errors.name ? (
                              <span className='text-sm text-red'>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='grid w-full'>
                          <div className='form-control'>
                            <input
                              placeholder={'Subject'}
                              type='text'
                              className='flex-grow w-full h-11 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='subject'
                              ml-12
                              name='subject'
                              value={values.subject}
                              onChange={handleChange}
                            />
                            {touched.subject && errors.subject ? (
                              <span className='text-sm text-red'>
                                {errors.subject}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className='mt-4'>
                          <textarea
                            className='flex-grow w-full p-4 mb-2
                          transition duration-200 bg-lightgraybg border
                          border-linecolor rounded appearance-none
                          focus:border-darkerblue focus:outline-noone
                          focus:shadow-outline'
                            id='messageBody'
                            name='message'
                            placeholder={'Type your message body here...'}
                            value={values.message}
                            onChange={handleChange}
                            rows='7'
                          />
                          {touched.message && errors.message ? (
                            <span className='text-sm text-red'>
                              {errors.message}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {/*footer*/}
                      <div className=' flex flex-col lg:grid lg:grid-cols-2 justify-between px-8'>
                        <div className='justify-start'>
                          <span className='font-normal text-charcoal text-sm mb-4 flex justify-start sm:w-/12   '>
                            By clicking submit, I agree that my feedback may be
                            viewed by the Delv community in compliance with the
                            Delv Terms of use and my profile privacy settings
                          </span>
                        </div>
                        <div className='justify-between lg:justify-end flex flex-row p-3'>
                          <button
                            type='reset'
                            onClick={handleReset}
                            className='cursor-pointer inline-flex items-center justify-center h-10 mx-auto w-14 font-medium tracking-wide text-darkerblue transition duration-200 bg-none hover:text-navyblue focus:shadow-outline focus:outline-noone'
                          >
                            Clear
                          </button>

                          <button
                            type='submit'
                            className='cursor-pointer p-3 inline-flex items-center justify-center h-10 mx-auto lg:w-40 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                          >
                            {!loading ? <ButtonLoader /> : <> Send mail </>}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className='opacity-60 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
};

export const SurveyModal = ({
  showSurveyModal,
  setShowSurveyModal,
  affiliations,
}) => {
  const dispatch = useDispatch();
  const { loading, success, errorMessage, error } = useSelector(
    (state) => state.institution,
  );
  const [targetMailingList, setMailingList] = useState([]);
  const myMentors = [];
  const myLearners = [];
  affiliations?.learners?.map((item) => myLearners.push(item?.email));
  affiliations?.mentors?.map((item) => myMentors.push(item?.email));

  const { user } = useSelector((state) => state.user);

  const initialValues = {
    surveyTopic: '',
    surveyLink: '',
    surveyDescription: '',
  };

  const validationSchema = Yup.object({
    surveyTopic: Yup.string().required('Topic is required'),
    surveyLink: Yup.string().required('Link body is required'),
    surveyDescription: Yup.string().required('Description body is required'),
  });

  const onSubmit = (values, onSubmitProps) => {
    dispatch(
      institutionActions.sendSurvey({
        message: values.surveyDescription,
        from: user?.email,
        to: targetMailingList,
        link: `${values.surveyLink}`,
        topic: values.surveyTopic,
      }),
    );
    onSubmitProps.resetForm();
  };

  const reset = () => {
    dispatch({ type: institutionTypes.INSTITUTION_RESET });
    setShowSurveyModal(false);
  };

  useEffect(() => {
    if (success.message === 'Survey sent successfully') {
      reset();
    } else if (error && errorMessage === 'Failed to submit survey') {
      reset();
    }
  }, [success, error]);

  return (
    <>
      {showSurveyModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, getFieldProps, isValid }) => (
              <Form>
                <ModalWrapper
                  title={'Send survey'}
                  closeModal={() => setShowSurveyModal(false)}
                  bodyContent={
                    <div className='relative'>
                      <div className=''>
                        <div className='mb-2'>
                          <div>
                            <h3 className='font-body text-textparagraph font-semibold '>
                              SURVEY AUDIENCE
                            </h3>

                            <div>
                              <select
                                onChange={(e) => {
                                  if (e.target.value === 'mentors') {
                                    setMailingList(myMentors);
                                  } else if (e.target.value === 'learners') {
                                    setMailingList(myLearners);
                                  }
                                }}
                                className='rounded-full  py-3 px-6  mb-2 mr-4 w-6/12 text-textparagraph  bg-lightgraybg justify-start text-sm font-base border border-linecolor  ring-opacity-50 hover:border-darkerblue focus:outline-noone'
                              >
                                <option value='Everyone'>
                                  Select audience
                                </option>
                                <option value='mentors'>
                                  {`My Mentors (${affiliations?.mentors?.length}) `}
                                </option>

                                <option value='learners'>
                                  {`My Learners (${affiliations?.learners?.length}) `}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <div>
                            <h3 className='font-body text-textparagraph font-semibold '>
                              TOPIC
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='surveyTopic'
                              name='surveyTopic'
                              {...getFieldProps('surveyTopic')}
                            />
                            {touched.surveyTopic && errors.surveyTopic ? (
                              <span className='text-sm text-red'>
                                {errors.surveyTopic}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className='pt-4'>
                          <h3 className='font-body text-textparagraph font-semibold '>
                            SURVEY DESCRIPTION
                          </h3>
                          <textarea
                            className='flex-grow w-full p-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='surveyDescription'
                            name='surveyDescription'
                            placeholder={'Describe the purpose of the survey..'}
                            rows='6'
                            {...getFieldProps('surveyDescription')}
                          />
                          {touched.surveyDescription &&
                          errors.surveyDescription ? (
                            <span className='text-sm text-red'>
                              {errors.surveyDescription}
                            </span>
                          ) : null}
                        </div>
                        <div className=''>
                          <div>
                            <label className='font-semibold text-textparagraph '>
                              SURVEY LINK
                            </label>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4  transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='surveyLink'
                              name='surveyLink'
                              {...getFieldProps('surveyLink')}
                            />
                            {touched.surveyLink && errors.surveyLink ? (
                              <span className='text-sm text-red'>
                                {errors.surveyLink}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  footer={true}
                  footerContent={
                    <button
                      className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                      disabled={!isValid || loading}
                    >
                      {loading ? <ButtonLoader /> : 'Send Survey'}
                    </button>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};
